<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
		<div style="display: flex;align-items: center;justify-content: space-between;">
			<div class="home">
				<div  @click="go('My')">我的主页</div>
				<div><</div>
				<div style="color: #E66617;">余额提现</div>
			</div>
			<div style="color: #08243C;font-size: 12px;margin-right: 10px;" @click="go('Withdrawal')">提现明细>></div>
		</div>
			
			<div class="off">
				<div class="offline">
					<div class="top">
						<div class="withdrawalMoney">
							<div>提现金额</div>
							<div class="inputMoney">
								<div>￥</div>
								<input type="number" v-model="money" placeholder="请输入提现金额">
							</div>
						</div>
						<div class="line"></div>
						<div class="already">
							<div>已提现金额</div>
							<div>￥{{ withdrawal }}</div>
						</div>
					</div>
					
					<div class="mid">可提现余额：{{ userMoney }}元<span style="color: #E66617;margin-left: 9px;"  @click="getMoney">申请提现</span></div>
					
					<div class="bottom" v-html="withdrawalagreement"></div>
				</div>
			</div>

			<info />
			<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
			<foot />
		</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import { withdrawal, commitWithdrawal } from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				userMoney: "", //用户可提现余额
				withdrawal: "", //用户已提现金额
				withdrawalagreement: "", //提现协议
				
				money: "", //提现金额
			};
		},
		created() {
			this.getData();
		},
		methods: {
			getData() {
			  withdrawal().then((res) => {
			    console.log(res);
			    this.userMoney = res.data.userMoney;
			    this.withdrawal = res.data.withdrawal;
			    this.withdrawalagreement = this.showHtml(res.data.withdrawalagreement);
			  });
			},
			//提现
			getMoney() {
			  commitWithdrawal({
			    money: this.money,
			  }).then((res) => {
			    if (res.code == 200) {
			      this.$message({
			        message: res.msg,
			        type: "success",
			      });
			
			      this.getData()
			    } else {
			      this.$message.error(res.msg);
			    }
			  });
			},
			showHtml(str) {
			  return str
			    .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
			    .replace(/&lt;/g, "<")
			    .replace(/&gt;/g, ">")
			    .replace(/&quot;/g, '"')
			    .replace(/&#39;/g, "'")
			    .replace(/&amp;nbsp;/g, "\u3000");
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;

			div {
				margin-right: 7px;
			}
		}
		.off {
			margin-top: 15px;
			padding: 0 10px;
			.offline {
				padding: 12px;
				font-size: 12px;
				background-color: #FFFFFF;
				color: #08243C;
				.top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #08243C;
					.withdrawalMoney {
						text-align: center;
						font-size: 12px;
						.inputMoney {
							margin-top: 15px;
							display: flex;
							align-items: center;
							input {
								width: 100px;
								font-size: 14px;
								background: none;
								outline: none;
								border: none;
							}
						}
					}
					.line {
						width: 1px;
						height: 35px;
						background-color: #E2E4E7;
						position: absolute;
						left: 50%;
						transform: translateX(-50%)
					}
					.already {
						text-align: center;
						font-size: 12px;
						div:last-child {
							font-size: 14px;
							margin-top: 15px;
						}
					}
				}
				.mid {
					font-size: 12px;
					margin-top: 14px;
				}
				.bottom {
					margin-top: 35px;
					font-size: 12px;
					line-height: 25px;
				}
			}
		}
		
	}
</style>
